import { alpha, Dialog, ListItemSecondaryAction, makeStyles, Slide, TextField, Theme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { TransitionProps } from "@material-ui/core/transitions";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import BarChartRoundedIcon from "@material-ui/icons/BarChartRounded";
import CachedIcon from "@material-ui/icons/Cached";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import FolderOutlinedIcon from "@material-ui/icons/FolderOutlined";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import MenuOpenRoundedIcon from "@material-ui/icons/MenuOpenRounded";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import PeopleOutlinedIcon from "@material-ui/icons/PeopleOutlined";
import RepeatRoundedIcon from "@material-ui/icons/RepeatRounded";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/nextjs";
import clsx from "clsx";
import Head from "next/head";
import React, {
  FC,
  forwardRef,
  MouseEvent,
  ReactElement,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AccountRecovery } from "../components/accounts/AccountRecovery";
import { GlobalAddPopover } from "../components/global-add/GlobalAddPopover";
import { Link } from "../components/Link";
import { Loading } from "../components/Loading";
import { OnboardingBuffet } from "../components/onboarding/OnboardingBuffet";
import { SyncStatus } from "../components/SyncStatus";
import { QuickAddTask } from "../components/tasks/QuickAddTask";
import { UserNav } from "../components/UserNav";
import { useAnalyticsContext } from "../context/AnalyticsContext";
import { AppContextActionType, AppContextNavState, useAppContext } from "../context/AppContext";
import { useCommandBarContext } from "../context/CommandBarContext";
import { useUserContext } from "../context/UserContext";
import { useOurRouter } from "../hooks/useOurRouter";
import { usePromise } from "../hooks/usePromise";
import CompassIcon from "../img/compass-icon.svg";
import IconDarkSvg from "../img/icon-dark.svg";
import IconSvg from "../img/icon.svg";
import TargetIcon from "../img/target-icon.svg";
import { reclaim } from "../reclaim-api";
import { ConnectedAccount } from "../reclaim-api/Accounts";
import { Status } from "../subpages/status";
import { arrequal } from "../utils/arrays";
import { browser, platform } from "../utils/platform";

const navWidth = 230;
const navWidthClosed = 64;
const appBarHeight = 68;
const HIDE_SHARE_ANIMATION_DELAY_MS = 5 * 60 * 1000; // 5 minutes

const SlideTransition = forwardRef<unknown, TransitionProps>(
  (props: TransitionProps & { children?: ReactElement }, ref: Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "stretch",
      height: "100%",
      minHeight: "100%",
      maxHeight: "100%",
    },
    drawer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "stretch",
      position: "relative",
    },
    drawerPaper: {
      position: "relative",
      width: navWidth,
      backgroundColor: theme.colors.logo.darkness,
      transition: theme.transitions.create(["width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
    },
    drawerPaperClosed: {
      width: navWidthClosed,
      transition: theme.transitions.create(["width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    main: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      [theme.breakpoints.up("md")]: {
        width: `calc(100vw - ${navWidth}px)`,
      },
    },
    appBar: {
      gridArea: "appbar",
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      justifyContent: "stretch",
      height: appBarHeight,
      minHeight: appBarHeight,
      backgroundColor: "transparent",
      borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.075)}`,
    },
    content: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "stretch",
      minHeight: 0,
    },
    toolbar: {
      display: "flex",
      flex: 1,
      height: "100%",
      paddingRight: theme.spacing(2),
      justifyContent: "space-between",
      [theme.breakpoints.up("md")]: {
        justifyContent: "flex-end",
      },
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      flex: 0,
      color: theme.palette.common.white,
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(1),
      },
    },
    logoButton: {
      marginRight: "auto",
      padding: theme.spacing(2, 2.5),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(1, 1.5),
      },
    },
    menuButton: {
      marginLeft: theme.spacing(1),
    },
    branding: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    logoIcon: {
      maxHeight: 24,
      maxWidth: 24,
    },
    logoText: {
      flexGrow: 1,
      marginLeft: -2,
      fontWeight: theme.typography.fontWeightMedium,
      [theme.breakpoints.up("md")]: {
        marginLeft: 6,
      },
    },
    pinIcon: {
      padding: 10,
    },
    parentLink: {},
    confetti: {},
    "@keyframes navLink__bg__fadeout": {
      from: { opacity: 1 },
      to: { opacity: 0 },
    },
    navLink: {
      display: "block",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
      },
      "&:not($parentLink).active": {
        backgroundColor: alpha(theme.palette.common.white, 0.05),
      },
      "&$confetti": {
        position: "relative",

        "&:after": {
          content: "''",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,

          animationName: "$navLink__bg__fadeout",
          animationDuration: 500,
          animationDelay: `${HIDE_SHARE_ANIMATION_DELAY_MS}ms`,
          animationIterationCount: 1,
          animationFillMode: "forwards",
          background: "url(/img/confetti.gif) -1px 0",
          backgroundSize: "cover",
        },
      },
    },
    navListItem: {
      padding: theme.spacing(1.5, "22px"),
      color: theme.palette.common.white,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.075),
      },
    },
    navIcon: {
      minWidth: 0,
      justifyContent: "center",
      color: theme.palette.common.white,
      opacity: 0.8,
    },
    navText: {
      margin: "0 0 0 21px",
      whiteSpace: "nowrap",
    },
    navSpacer: {
      flex: 1,
    },
    nestedList: {
      paddingTop: 0,
    },
    nestedNavListItem: {
      padding: theme.spacing(1, 1, 1, 5.25),
    },
    nestedNavText: {
      // fontSize: "0.95em",
    },
    nestedNavToggle: {
      opacity: 1,
      right: 8,
      transition: theme.transitions.create(["opacity"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
        delay: 25,
      }),
    },
    nestedNavToggleClosed: {
      opacity: 0,
      transition: theme.transitions.create(["opacity"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shortest,
        delay: 0,
      }),
    },
    title: {
      flex: 1,
      padding: 0,
      fontSize: "1.5em",
      lineHeight: "1em",
      overflow: "visible",
      [theme.breakpoints.up("md")]: {
        marginLeft: theme.spacing(3),
        padding: 0,
        fontSize: "2em",
      },
      "&:empty": {
        display: "none",
      },
    },
    titleDivider: {
      color: theme.palette.grey.A100,
    },
    searchField: {
      width: "auto",
      marginRight: theme.spacing(4),
      padding: theme.spacing(2, 3),
      [theme.breakpoints.up("md")]: {
        padding: 0,
      },
    },
    input: {
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightMedium,
      transition: "color .2s ease",
      width: "8em",
      "& input": {
        cursor: "pointer",
      },
      "&.open": {},
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
      [theme.breakpoints.up("md")]: {
        color: theme.palette.grey[700],
        "&:hover": {
          color: theme.palette.grey[900],
        },
      },
    },
    keyboardKey: {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: 4,
      padding: "2px 4px",
      whiteSpace: "nowrap",
    },
    searchIcon: {
      color: theme.palette.grey[400],
      marginRight: theme.spacing(2.5),
      [theme.breakpoints.up("md")]: {
        marginRight: 0,
      },
    },
    compassIcon: {
      height: 20,
      padding: 1,
      width: 20,
    },
    targetIcon: {
      height: 20,
      width: 20,
    },
  }),
  { index: 1, name: "AppLayout" }
);

type HelpContext = {
  label: string;
  categoryId: number;
};

type AppLayoutProps = {
  title: string | string[] | ReactElement;
  disableScroll?: boolean;
  disableTitle?: boolean;
  disableSync?: boolean;
};

// eslint-disable-next-line max-lines-per-function
export const AppLayout: FC<AppLayoutProps> = ({
  title,
  disableScroll = false,
  disableTitle = false,
  disableSync,
  children,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  const router = useOurRouter<{ date?: string; invite?: number }>();

  const {
    state: { nav, crumbs, title: docTitle },
    dispatch,
  } = useAppContext();
  const [{ user, status }] = useUserContext();
  const {
    state: { intercom, segment, sentry },
  } = useAnalyticsContext();
  const {
    state: { CommandBar },
  } = useCommandBarContext();

  const {
    data: mainAccount,
    loading: mainAccountLoading,
    load: loadMainAccount,
  } = usePromise<ConnectedAccount>(reclaim.accounts.main, []);

  const help = useMemo<HelpContext>(() => {
    const section = router.pathname
      .replace(/^\/|\/$/, "")
      .split("/")
      .shift();

    switch (section) {
      case "tasks":
        return { label: "Tasks Help", categoryId: 514 };
      case "habits":
        return { label: "Habits Help", categoryId: 508 };
      default:
        return { label: "Help", categoryId: 621 };
    }
  }, [router.pathname]);

  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>(() => ({
    sync: router?.pathname?.startsWith("/sync"),
    settings:
      router?.pathname?.startsWith("/settings") ||
      (browser().isBrowser && !!document.querySelector("#intercom-positioner-tree")),
  }));

  const [gettingStartedOpen, setGettingStartedOpen] = useState<boolean>(false);
  const [accountRecoveryOpen, setAccountRecoveryOpen] = useState<boolean>(false);

  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);

  const setNav = useCallback(
    (opts: Partial<AppContextNavState>) => {
      dispatch({
        type: AppContextActionType.Nav,
        payload: opts,
      });
    },
    [dispatch]
  );

  // Set document title
  useEffect(() => {
    if (typeof title === "string" && docTitle !== title) {
      dispatch({ type: AppContextActionType.Title, payload: title });
      dispatch({ type: AppContextActionType.Crumbs, payload: [title] });
      return;
    }

    if (Array.isArray(title) && !arrequal(title, crumbs)) {
      const page = title[title.length - 1];
      dispatch({ type: AppContextActionType.Crumbs, payload: title });
      dispatch({ type: AppContextActionType.Title, payload: page });
      return;
    }
  }, [crumbs, dispatch, docTitle, title]);

  // Shrink nav when switching to mobile layout
  useEffect(() => {
    if (small) setNav({ pin: false, hover: false, expand: false });
  }, [setNav, small]);

  // Force account recovery dialog if main account invalid
  useEffect(() => {
    if (!!mainAccountLoading || !mainAccount) return;
    if (!mainAccount.valid) setAccountRecoveryOpen(true);
  }, [mainAccount, mainAccountLoading]);

  const handleLogoClick = useCallback(
    (e) => {
      if (!browser().isMobile) return;
      e.preventDefault();
      setNav({ hover: !nav.hover });
    },
    [nav.hover, setNav]
  );

  const handleHover = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (small) return;

      if (hoverTimeout.current) {
        clearTimeout(hoverTimeout.current);
        hoverTimeout.current = null;
      }

      if (browser().isBrowser && !!document.querySelector("#intercom-positioner-tree") && !nav.pin) {
        setNav({ pin: true, hover: true });
        return;
      }

      if ("mouseenter" === e.type) {
        hoverTimeout.current = setTimeout(() => {
          if (!nav.hover) setNav({ hover: true });
        }, 150);
      } else {
        hoverTimeout.current = setTimeout(() => {
          if (!!nav.hover) setNav({ hover: false });
        }, 300);
      }
    },
    [nav.hover, nav.pin, setNav, small]
  );

  const handleBackdropClick = useCallback(() => {
    if (nav.pin || nav.hover || nav.expand) setNav({ pin: false, hover: false, expand: false });
  }, [nav.expand, nav.hover, nav.pin, setNav]);

  const handleDrawerToggle = useCallback(
    (pin = !nav.pin) => {
      const next = pin || (browser().isBrowser && !!document.querySelector("#intercom-positioner-tree"));

      if (!next) {
        if (hoverTimeout.current) {
          clearTimeout(hoverTimeout.current);
          hoverTimeout.current = null;
        }
      }

      if (next !== nav.pin) setNav({ pin: next, hover: false, expand: false });
    },
    [nav.pin, setNav]
  );

  const handleMenuExpand = useCallback(
    (e: MouseEvent, name: string) => {
      e.stopPropagation();
      e.preventDefault();

      const expand = !expanded[name];
      setExpanded((prev) => ({ ...prev, [name]: expand }));
    },
    [expanded]
  );

  const handleCloseGettingStarted = useCallback(() => setGettingStartedOpen(false), []);

  const handleCloseAccountRecovery = useCallback(async () => {
    const res = await loadMainAccount();
    if (!!res.valid) setAccountRecoveryOpen(false);
  }, [loadMainAccount]);

  const mainListItems = useMemo(
    () => (
      <List component="nav" disablePadding>
        {!!user?.features?.focus?.enabled && (
          <Link href="/focus" underline="none" className={classes.navLink}>
            <ListItem button className={classes.navListItem}>
              <ListItemIcon className={classes.navIcon}>
                <TargetIcon className={classes.targetIcon} />
              </ListItemIcon>
              <ListItemText primary="Focus" className={classes.navText} />
            </ListItem>
          </Link>
        )}

        <Link href="/planner" underline="none" className={classes.navLink}>
          <ListItem button className={classes.navListItem}>
            <ListItemIcon className={classes.navIcon}>
              <CalendarTodayOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Planner" className={classes.navText} />
          </ListItem>
        </Link>

        <Link href="/stats" underline="none" className={classes.navLink}>
          <ListItem button className={classes.navListItem}>
            <ListItemIcon className={classes.navIcon}>
              <BarChartRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Stats" className={classes.navText} />
          </ListItem>
        </Link>
      </List>
    ),
    [
      classes.navIcon,
      classes.navLink,
      classes.navListItem,
      classes.navText,
      classes.targetIcon,
      user?.features?.focus?.enabled,
    ]
  );

  const secondaryListItems = useMemo(
    () => (
      <List component="nav" disablePadding>
        <Link href="/tasks" underline="none" className={classes.navLink}>
          <ListItem button className={classes.navListItem}>
            <ListItemIcon className={classes.navIcon}>
              <AssignmentTurnedInOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Tasks" className={classes.navText} />
          </ListItem>
        </Link>

        <Link href="/habits" underline="none" className={classes.navLink}>
          <ListItem button className={classes.navListItem}>
            <ListItemIcon className={classes.navIcon}>
              <RepeatRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Habits" className={classes.navText} />
          </ListItem>
        </Link>

        {!!user?.features?.smartOneOnOnes?.enabled && (
          <Link href="/one-on-ones" underline="none" className={classes.navLink}>
            <ListItem button className={classes.navListItem}>
              <ListItemIcon className={classes.navIcon}>
                <PeopleOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Smart 1:1s" className={classes.navText} />
            </ListItem>
          </Link>
        )}

        {!!user?.features?.projects?.enabled && (
          <Link href="/projects" underline="none" className={classes.navLink}>
            <ListItem button className={classes.navListItem}>
              <ListItemIcon className={classes.navIcon}>
                <FolderOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Projects" className={classes.navText} />
            </ListItem>
          </Link>
        )}

        {user?.features.sync?.enabled && (
          <Link href="/sync" component="div" underline="none" className={clsx(classes.navLink, classes.parentLink)}>
            <ListItem button className={classes.navListItem}>
              <ListItemIcon className={classes.navIcon}>
                <CachedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Calendar Sync" className={classes.navText} />
              <ListItemSecondaryAction
                className={clsx(classes.nestedNavToggle, {
                  [classes.nestedNavToggleClosed]: !nav.open,
                })}
              >
                <IconButton className={classes.navIcon} onClick={(e) => handleMenuExpand(e, "sync")}>
                  {!expanded["sync"] ? (
                    <ChevronRightRoundedIcon fontSize="small" color="inherit" />
                  ) : (
                    <ExpandMoreRoundedIcon fontSize="small" color="inherit" />
                  )}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={nav.open && expanded["sync"]} timeout="auto" unmountOnExit>
              <List component="div" className={classes.nestedList}>
                <Link href="/sync" underline="none" className={classes.navLink}>
                  <ListItem button className={clsx(classes.navListItem, classes.nestedNavListItem)}>
                    <ListItemText
                      primary="All Syncs"
                      disableTypography
                      className={clsx(classes.navText, classes.nestedNavText)}
                    />
                  </ListItem>
                </Link>
                <Link href="/sync/activity" underline="none" className={classes.navLink}>
                  <ListItem button className={clsx(classes.navListItem, classes.nestedNavListItem)}>
                    <ListItemText
                      primary="Activity"
                      disableTypography
                      className={clsx(classes.navText, classes.nestedNavText)}
                    />
                  </ListItem>
                </Link>
              </List>
            </Collapse>
          </Link>
        )}

        <Divider />

        <Link
          href="/settings/general"
          component="div"
          underline="none"
          className={clsx(classes.navLink, classes.parentLink)}
          data-intercom-target="nav-settings-general"
        >
          <ListItem button className={classes.navListItem}>
            <ListItemIcon className={classes.navIcon}>
              <SettingsOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Settings" className={classes.navText} />
            <ListItemSecondaryAction
              className={clsx(classes.nestedNavToggle, {
                [classes.nestedNavToggleClosed]: !nav.open,
              })}
            >
              <IconButton className={classes.navIcon} onClick={(e) => handleMenuExpand(e, "settings")}>
                {!expanded["settings"] ? (
                  <ChevronRightRoundedIcon fontSize="small" color="inherit" />
                ) : (
                  <ExpandMoreRoundedIcon fontSize="small" color="inherit" />
                )}
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Collapse in={nav.open && expanded["settings"]} timeout="auto" unmountOnExit>
            <List component="div" className={classes.nestedList}>
              <Link href="/settings/general" underline="none" className={classes.navLink}>
                <ListItem button className={clsx(classes.navListItem, classes.nestedNavListItem)}>
                  <ListItemText
                    primary="General"
                    disableTypography
                    className={clsx(classes.navText, classes.nestedNavText)}
                  />
                </ListItem>
              </Link>
              <Link href="/settings/accounts" underline="none" className={classes.navLink}>
                <ListItem button className={clsx(classes.navListItem, classes.nestedNavListItem)}>
                  <ListItemText
                    primary="Accounts"
                    disableTypography
                    className={clsx(classes.navText, classes.nestedNavText)}
                  />
                </ListItem>
              </Link>
              <Link
                href="/settings/hours"
                underline="none"
                className={classes.navLink}
                data-intercom-target="nav-settings-hours"
              >
                <ListItem button className={clsx(classes.navListItem, classes.nestedNavListItem)}>
                  <ListItemText
                    primary="Hours"
                    disableTypography
                    className={clsx(classes.navText, classes.nestedNavText)}
                  />
                </ListItem>
              </Link>
              <Link href="/settings/colors" underline="none" className={classes.navLink}>
                <ListItem button className={clsx(classes.navListItem, classes.nestedNavListItem)}>
                  <ListItemText
                    primary="Colors"
                    disableTypography
                    className={clsx(classes.navText, classes.nestedNavText)}
                  />
                </ListItem>
              </Link>
              <Link href="/settings/notifications" underline="none" className={classes.navLink}>
                <ListItem button className={clsx(classes.navListItem, classes.nestedNavListItem)}>
                  <ListItemText
                    primary="Notifications"
                    disableTypography
                    className={clsx(classes.navText, classes.nestedNavText)}
                  />
                </ListItem>
              </Link>
              <Link href="/settings/integrations" underline="none" className={classes.navLink}>
                <ListItem button className={clsx(classes.navListItem, classes.nestedNavListItem)}>
                  <ListItemText
                    primary="Integrations"
                    disableTypography
                    className={clsx(classes.navText, classes.nestedNavText)}
                  />
                </ListItem>
              </Link>
            </List>
          </Collapse>
        </Link>
      </List>
    ),
    [
      classes.navIcon,
      classes.navLink,
      classes.navListItem,
      classes.navText,
      classes.nestedList,
      classes.nestedNavListItem,
      classes.nestedNavText,
      classes.nestedNavToggle,
      classes.nestedNavToggleClosed,
      classes.parentLink,
      expanded,
      handleMenuExpand,
      nav.open,
      user?.features?.projects?.enabled,
      user?.features?.smartOneOnOnes?.enabled,
      user?.features.sync?.enabled,
    ]
  );

  const tertiaryListItems = useMemo(
    () => (
      <List component="nav" disablePadding>
        <Link
          target="reclaim-help"
          underline="none"
          onClick={(e) => {
            e.preventDefault();

            if (!small) {
              CommandBar?.open("", { categoryFilter: help.categoryId });
            } else {
              handleDrawerToggle(false);
              intercom?.("showNewMessage", "I need a little help...");
            }
          }}
          className={classes.navLink}
          data-intercom-target="nav-help"
        >
          <ListItem button className={classes.navListItem}>
            <ListItemIcon className={classes.navIcon}>
              <HelpOutlineOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={help.label} className={classes.navText} />
          </ListItem>
        </Link>

        <Link
          underline="none"
          className={classes.navLink}
          data-intercom-target="nav-getting-started"
          onClick={(e) => {
            setGettingStartedOpen(true);
            segment?.track("Getting Started Clicked", {
              category: "Nav",
            });
          }}
        >
          <ListItem button className={classes.navListItem}>
            <ListItemIcon className={classes.navIcon}>
              <CompassIcon className={classes.compassIcon} />
            </ListItemIcon>
            <ListItemText primary="Get Started" className={classes.navText} />
          </ListItem>
        </Link>

        <Link
          href={"/share-reclaim"}
          underline="none"
          className={clsx(classes.navLink, classes.confetti)}
          data-intercom-target="nav-share-reclaim"
          onClick={(e) => {
            segment?.track("Share Reclaim Clicked", {
              category: "Nav",
            });
          }}
        >
          <ListItem button className={classes.navListItem}>
            <ListItemIcon className={classes.navIcon}>
              <PeopleAltOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Share Reclaim" className={classes.navText} />
          </ListItem>
        </Link>

        <Divider />

        {!!user && <UserNav />}
      </List>
    ),
    [
      CommandBar,
      classes.compassIcon,
      classes.confetti,
      classes.navIcon,
      classes.navLink,
      classes.navListItem,
      classes.navText,
      help.categoryId,
      help.label,
      handleDrawerToggle,
      intercom,
      segment,
      small,
      user,
    ]
  );

  const renderTitle = useMemo(() => {
    if (disableTitle) return null;
    else
      return (
        <Typography variant="h1" color="inherit" noWrap className={classes.title}>
          {typeof title === "string" && title}
          {Array.isArray(title) &&
            title
              .map<ReactNode>((t, idx) => <span key={`${idx}-${t}`}>{t}</span>)
              .reduce((acc, curr, idx) => [
                acc,
                <span key={`${idx}-divider`} className={classes.titleDivider}>
                  {" "}
                  /{" "}
                </span>,
                curr,
              ])}
        </Typography>
      );
  }, [classes.title, classes.titleDivider, disableTitle, title]);

  return (
    <Box key="AppLayout" className={classes.root}>
      <Head>
        <title>{/^Reclaim/.test(docTitle) ? docTitle : `${docTitle} | Reclaim`}</title>
        <meta name="robots" content="noindex" key="robots" />
      </Head>

      <Drawer
        variant={small ? "temporary" : "permanent"}
        anchor="left"
        classes={{
          root: classes.drawer,
          paper: clsx(classes.drawerPaper, {
            [classes.drawerPaperClosed]: !nav.open,
          }),
        }}
        open={nav.open}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
        {...(small ? { onBackdropClick: handleBackdropClick } : {})}
      >
        <Box className={classes.toolbarIcon}>
          <Box className={classes.branding}>
            <IconButton
              component={Link}
              color="inherit"
              aria-label="Reclaim.ai"
              href="/"
              className={classes.logoButton}
              onClick={handleLogoClick}
            >
              <IconSvg className={classes.logoIcon} />
            </IconButton>
            {nav.open && (
              <>
                <Typography variant="h4" className={classes.logoText}>
                  Reclaim
                </Typography>
                {!small && (
                  <IconButton
                    className={classes.pinIcon}
                    color="inherit"
                    aria-label={`${nav.pin ? "close" : "open"} menu`}
                    onClick={() => handleDrawerToggle()}
                  >
                    {nav.pin ? (
                      <MenuOpenRoundedIcon fontSize="small" />
                    ) : (
                      <Icon
                        className="fa fa-thumbtack"
                        style={{
                          fontSize: 16,
                          opacity: 0.8,
                        }}
                      />
                    )}
                  </IconButton>
                )}
              </>
            )}
          </Box>
        </Box>

        <Divider />
        {mainListItems}
        <Divider />
        {secondaryListItems}
        <Box className={classes.navSpacer} />
        {tertiaryListItems}
      </Drawer>

      {/* Main content */}
      <Box
        className={classes.main}
        style={{
          overflow: disableScroll ? "hidden" : "auto",
        }}
      >
        <Box className={classes.appBar}>
          <Hidden smDown>{renderTitle}</Hidden>
          <Toolbar disableGutters className={classes.toolbar}>
            <Hidden mdUp>
              <IconButton
                component={Link}
                color="inherit"
                aria-label="Reclaim.ai"
                href="/"
                className={classes.logoButton}
              >
                <IconDarkSvg className={classes.logoIcon} />
              </IconButton>
              {renderTitle}
            </Hidden>

            {!disableSync && <SyncStatus />}

            <Hidden smDown>
              <TextField
                className={clsx(classes.searchField)}
                value="Search"
                size="medium"
                fullWidth
                InputProps={{
                  className: clsx(classes.input),
                  startAdornment: <SearchRoundedIcon className={classes.searchIcon} fontSize="small" color="action" />,
                  endAdornment: (
                    <>
                      <span className={classes.keyboardKey}>{platform().isMac ? "⌘" : "Ctrl"} K</span>
                    </>
                  ),
                }}
                onClick={(e) => {
                  e.preventDefault();
                  CommandBar?.open();
                }}
              />
            </Hidden>

            {!!user && (
              <Box data-intercom-target="nav-quick-add">
                <GlobalAddPopover>
                  <QuickAddTask />
                </GlobalAddPopover>
              </Box>
            )}

            <Hidden mdUp>
              <IconButton
                edge="end"
                className={classes.menuButton}
                color="inherit"
                aria-label="toggle menu"
                onClick={() => handleDrawerToggle(true)}
              >
                <MenuRoundedIcon />
              </IconButton>
            </Hidden>
          </Toolbar>
        </Box>
        <Box component="main" className={classes.content}>
          <SentryErrorBoundary
            fallback={
              <Status
                code={5000}
                message="We are so sorry, but there seems to be an issue. Our engineers have been notified and are working on a fix."
              />
            }
            showDialog
            onError={() => {
              sentry?.addBreadcrumb({
                category: "error",
                message: "Page Error",
                level: sentry.Severity.Critical,
              });
            }}
          >
            {status === "loading" ? <Loading /> : children}
          </SentryErrorBoundary>

          {/* <AppUpdate /> */}
        </Box>

        {/* Get Started dialog */}
        <Dialog
          fullScreen
          open={gettingStartedOpen}
          onClose={handleCloseGettingStarted}
          TransitionComponent={SlideTransition}
          TransitionProps={{ timeout: 250 }}
        >
          <OnboardingBuffet onClose={handleCloseGettingStarted} />
        </Dialog>

        {/* Account recovery dialog */}
        <Dialog
          fullScreen
          open={accountRecoveryOpen}
          onClose={handleCloseAccountRecovery}
          TransitionComponent={SlideTransition}
          TransitionProps={{ timeout: 250 }}
        >
          <AccountRecovery onClose={handleCloseAccountRecovery} />
        </Dialog>
      </Box>
    </Box>
  );
};
